import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Row,
  Col
} from "react-bootstrap";

//VALIDATIONS
import { validateUsers } from "../../Helpers/Validations/FormValidation/FormValidation";

//CSS
import "../AddUser/AddUser.css";


//INputError
import InputError from "../../Components/InputError/InputError";

//Component
import Navbar from "../../Components/Navbar/Navbar";
import MYTTextField from "../../Components/Forms/MYTTextField";
import MYTNumberField from "../../Components/Forms/MYTNumberField";
import MYTCancelBtn from "../../Components/Buttons/MYTCancelBtn";
import MYTClearBtn from "../../Components/Buttons/MYTClearBtn";
import MYTCreateBtn from "../../Components/Buttons/MYTCreateBtn";
import MYTPasswordField from "../../Components/Forms/MYTPasswordField";
import { UpdateUser } from "../../Helpers/API/usersApi";
import { useNavigate } from "react-router-dom";

function Chart() {
  const [inactive, setInactive] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()

  function togglePassword() {
    setShowPassword(!showPassword);
  }
  const [addUser, setAddUser] = useState({
    name: "",
    password: "",
    email: "",
    mobileNum: ""

  });

  //REQUIRED ERROR HANDLING
  const [isError, setIsError] = useState({
    name: false,
    email: false,
    mobileNum: false
  });

  const handleClear = () => {
    Object.keys(addUser).forEach((key) => {
      addUser[key] = "";
    });
    setAddUser({ ...addUser, name: "name", email: "email",mobileNum: "mobileNum" });
  };
  // const handleAddChange = (e) => {
  //   const { name, value } = e.target;
  //   setAddUser((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  //   console.log(name, value);
  // };

  async function updateUser() {
    if (validateUsers(addUser, setIsError)) 
      setIsClicked(true);
    
  }

  

  

  return (
    <div className="page">
      <Navbar
        onCollapse={(inactive) => {
          setInactive(inactive);
        }}
        active={"FORMS"}
      />
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <Row>
          <Col xs="6">
            <h1 className="page-title left">UPDATE USERS</h1>
          </Col>
        </Row>
        <hr className="hr-line" />
          <Row id="form_id">
          <Col xs={10} md={8} className="mt-2">
            <MYTTextField
              required
              id="name"
              type={`inline`}
              formLabel={`Name`}
              size={`sm`}
              placeholder={`Enter Name`}
              name="name"
            />
            <InputError isValid={isError.name} message={"Name is Required"} />
            <MYTPasswordField
              required
              type={'inline'}
              id="password"
              formLabel={`Password`}
              placeholder={`Enter Password`}
              showPassword={showPassword}
              onShowPasswordClick={togglePassword}
              size={`sm`}
              onChange={(e) => setPassword(e.target.value)}
              
            />
            <InputError isValid={isError.password} message={"Password is Required"} />
            <MYTTextField
              required
              id="email"
              type={`inline`}
              formLabel={`Email`}
              size={`sm`}
              placeholder={`Enter Email here`}
              email="email"
            />
            <InputError isValid={isError.email} message={"Email is Required"} />
            <MYTNumberField
              required
              id="mobileNum"
              type={`inline`}
              formLabel={`Mobile Number`}
              size={`sm`}
              placeholder={`09xxxxxxxxx`}
              sideInput
              sideInputType={`string`}
              sideInputValue={`+63`}
              mobileNum="mobileNum"
            />
            <InputError isValid={isError.mobileNum} message={"Mobile Number is Required"} />
          </Col>
        </Row>
        <Row className="mt-5 p-3">
          <Col xs={10} sm={3}>
            <MYTCancelBtn size={`sm`} />
          </Col>
          <Col sm={3}></Col>
          <Col xs={10} sm={3}>
            <MYTClearBtn size={`sm`} obj={handleClear} />
          </Col>
          <Col xs={10} sm={3}>
            <MYTCreateBtn size={`sm`} onClick={updateUser} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Chart;
