import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import Navbar from "../../Components/Navbar/Navbar";
import "./Settings.css";

import MYTTextField from "../../Components/Forms/MYTTextField";
import MYTPasswordField from "../../Components/Forms/MYTPasswordField";
import MYTUpdateBtn from "../../Components/Buttons/MYTUpdateBtn";
import Image from "../../Assets/Images/no-data-prompt.png";

export default function Settings() {
  const [inactive, setInactive] = useState(true)

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

function togglePassword() {
  setShowPassword(!showPassword);
}

  return (
  <div className='page'>
    <Navbar
        onCollapse={(inactive) => {
        setInactive(inactive)
        }}
        active={'MAPS'} 
    />
    <div className={`container ${inactive ? "inactive" : "active"}`}>
        <Row>
            <Col xs='6'>
                <h1 className='page-title left'>USER PROFILE</h1>
            </Col>
        </Row>
        
        <hr className="hr-line" />
        
        <Row>
            <div className="login-cont">
                <Row>
                    <Col>
                        <img src={Image} width={100} height={100} alt="" />
                    </Col>  
                    <Col>
                        <Col>
                            <h1>Charles Dave Quibido</h1>
                        </Col>
                        <Col>
                            quibidocharlesdave@gmail.com
                        </Col>
                        <Col>
                            USER
                        </Col>
                    </Col>
                </Row>
            </div>
        </Row>

        <hr className="hr-line" />

        <Row>
            <Col xs="6">
                <h1 className="page-title left">EDIT PROFILE</h1>
            </Col>
        </Row>

        <Row id="form_id">
            <Col xs={10} md={6} className="mt-2">
                <MYTTextField
                    required
                    id="name"
                    type={`inline`}
                    formLabel={`Name`}
                    size={`sm`}
                    placeholder={`Enter Name`}
                    name="name"
                />
                <MYTTextField
                    required
                    id="email"
                    type={`inline`}
                    formLabel={`Email`}
                    size={`sm`}
                    placeholder={`Enter Email here`}
                    email="email"
                />
            </Col>
            <Col xs={10} md={6} className="mt-2">
                <MYTTextField
                    required
                    id="organization"
                    type={`inline`}
                    formLabel={`Organization`}
                    size={`sm`}
                    placeholder={`Enter Organization`}
                    organization="organizationn"
                />
                <MYTTextField
                    required
                    id="address"
                    type={`inline`}
                    formLabel={`Address`}
                    size={`sm`}
                    placeholder={`Enter Address here`}
                    address="address"
                />
            </Col>
        </Row>

        <hr className="hr-line" />

        <Row>
          <Col xs="6">
            <h1 className="page-title left">SECURITY</h1>
          </Col>
        </Row>

        <Row>
            <MYTPasswordField
                required
                type={'inline'}
                id="CurrentPassword"
                formLabel={`Current Password`}
                placeholder={`Enter Current Password`}
                showPassword={showPassword}
                onShowPasswordClick={togglePassword}
                size={`sm`}
                onChange={(e) => setPassword(e.target.value)}
            />
            <MYTPasswordField
                required
                type={'inline'}
                id="NewPassword"
                formLabel={`New Password`}
                placeholder={`Enter New Password`}
                showPassword={showPassword}
                onShowPasswordClick={togglePassword}
                size={`sm`}
                onChange={(e) => setPassword(e.target.value)}
            />
            <MYTPasswordField
                required
                type={'inline'}
                id="ConfirmPass"
                formLabel={`Confirm Password`}
                placeholder={`Enter Password`}
                showPassword={showPassword}
                onShowPasswordClick={togglePassword}
                size={`sm`}
                onChange={(e) => setPassword(e.target.value)}
            />
        </Row>
        <Row className="justify-content-center">
            <MYTUpdateBtn />
        </Row>
    </div>
  </div>
    
  );
}