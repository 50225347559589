import React, { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

//CSS
import "../Links/Links.css";
import "../../Pages/Dashboard/Dashboard.css";

//Component
import Navbar from "../../Components/Navbar/Navbar";
import MYTClearBtn from "../../Components/Buttons/MYTClearBtn";
import MYTCreateBtn from "../../Components/Buttons/MYTCreateBtn";
import { createUrlApi } from "../../Helpers/API/urlsApi";
import { useNavigate } from "react-router-dom";
function CreateNew() {
  const [inactive, setInactive] = useState(false);
  const navigate = useNavigate()

   const[formData, setFormData]=useState({
    link: '',
    title: '',
    customized_back_link: ''
   })

   async function createlink(){
    const response = await createUrlApi(formData.link, formData.title, formData.customized_back_link)
    console.log(response)
    if(response.data){
      navigate("/links")
    }
    if(response.error){
      alert("Error")
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createlink()
  };

  const handleChange = (event) => {
    setFormData({...formData, [event.target.id]: event.target.value })
  };

  return (
    <div className="page">
      <Navbar
        onCollapse={(inactive) => {
          setInactive(inactive);
        }}
        active={"TABLES"}
      />
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <Row>
          <Col xs="6" className="mt-3">
            <h1 className="page-title left">NEW LINK</h1>
          </Col>
            <p>
            <label className="label-form" htmlFor="url">Enter Url</label>
              <input 
                type="text" 
                className="form-control mt-3 placeholder_form" 
                id="link" 
                placeholder="https://shrnqr.ph"
                value={formData.link}
                onChange={handleChange}
              />
            </p>    
              <p>
              <label className="label-form" htmlFor="label-title">Link Name/Title</label>
              <input 
                type="text" 
                className="form-control mt-3 placeholder_form" 
                id="title" 
                placeholder="Shrnqr Website"
                value={formData.title}
                onChange={handleChange}
              />
              </p>
              <p>
              <label className="label-form" htmlFor="label-title">Customized Back Link</label>
              <input 
                type="text" 
                className="form-control mt-3 placeholder_form" 
                id="customized_back_link" 
                placeholder="myt-shrnqr"
                value={formData.customized_back_link}
                onChange={handleChange}
              />
              </p>
             
        
              <Row className="mt-5 p-3">
                <Col sm={3}></Col>
                <Col xs={10} sm={3}>
                  <MYTClearBtn  />
                </Col>
                <Col xs={10} sm={3}>
                 <MYTCreateBtn onClick={handleSubmit}  />
                </Col>
              </Row>
        </Row>
      </div>
    </div>
  );
}

export default CreateNew;
