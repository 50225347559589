import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form , Card} from "react-bootstrap";

//CSS
import "../AddUser/AddUser.css";

//Component
import Navbar from "../../Components/Navbar/Navbar";
import MYTClearBtn from "../../Components/Buttons/MYTClearBtn";
import MYTUpdateBtn from "../../Components/Buttons/MYTUpdateBtn";
import { linkDetails, updateLink, updtLink } from "../../Helpers/API/urlsApi";
import { useNavigate, useParams } from "react-router-dom";
function UpdateLink() {
  const [inactive, setInactive] = useState(false);
  const [shortenedUrl,setShortenedUrl] = useState("")
  const [linkTitle, setLinkTitle] = useState([])
  const navigate = useNavigate()
  let {id} = useParams();


  const[formData, setFormData]=useState({
    newtitle: "",
    link: "api.shrnqr.ph",
   })

   async function eachLinkDetails(){
    const date_from = "2023-05-29 00:00:00"
    const date_to = "2023-06-1 23:59:59"
    const response = await linkDetails(id, date_from, date_to);
    //const response = await linkDetails(id, date_from, date_to);
    console.log(response)
    setLinkTitle(response.data.dashboard_information[4][0].URL_name)
    if(response.data){
      
    }
    console.log(response)
    if(response.error){
    }
  }
useEffect(()=>{
    //generateShortenedUrl();
    eachLinkDetails();
  },[])


   async function linkUpdate(){
    const response = await updtLink(id, formData.newtitle, formData.link)
    console.log(response)
    if(response.data){
      const shortenedUrl = response.data.shortened_url;
      setShortenedUrl(shortenedUrl);
      console.log( shortenedUrl)
      navigate("/links")
    }
    if(response.error){
      alert("Error")
    }
  }

  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    linkUpdate()
  };

  const handleChange = (event) => {
    setFormData({...formData, [event.target.id]: event.target.value })
  };

  return (
    <div className="page">
      <Navbar
        onCollapse={(inactive) => {
          setInactive(inactive);
        }}
        active={"TABLES"}
      />
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <Row>
          <Col xs="6" className="mt-3">
            <h1 className="page-title left">UPDATE LINK</h1>
          </Col>

              <p className="mt-4">
                
                <Row>
                <Col xs="13 p-2">
              <Card bg="white" style={{border: "1px solid #003049"}}>
                <Card.Body>
                  <Row>
                    {/* <label className="subtitle d-block pt-3 center">{dashboardDate.date}</label> */}
                    <label className="content-text d-block pt-3 center" >{linkTitle}</label>
                    <label className="text-label d-block pt-4 center">URL TITLE</label>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
                </Row>
              </p>
              <p>
              <input 
                type="text" 
                class="form-control mt-3" 
                id="newtitle" 
                placeholder="EXAMPLE LINK"
                value={formData.newtitle}
                onChange={handleChange}
              />
              </p>
              <label for="label-title">New Link Name/Title</label>

              <Row className="mt-5 p-3">
                <Col sm={3}></Col>
                <Col xs={10} sm={3}>
                  <MYTClearBtn  />
                </Col>
                <Col xs={10} sm={3}>
                 <MYTUpdateBtn onClick={handleSubmit}  />
                </Col>
              </Row>
        </Row>
      </div>
    </div>
  );
}


export default UpdateLink;