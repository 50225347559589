import { getAPICall, postAPICall } from "./axiosMethodCalls";
import {
  getUser,
  getKey,
  getToken,
  refreshPage,
  getRoleId,
  getRoleId1,
  getToken1,
  getRequester,
} from "../Utils/Common";

/***************************
 * CREATE-NEW LINK
 ***************************/

export const createUrlApi = async (link, name, back_link) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "urls/create",
      {
        requester: getRequester().replace(/['"]+/g, ""),
        token: getToken().replace(/['"]+/g, ""),
        original_url: link,
        back_link:back_link,
        name: name
      }
    );
    return { data: response.data };
  } catch (error) {
    console.log(error);
    return { error: error.response };
  }
};

/***************************
 * Link Manager
 ***************************/

export const linkManager = async () => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "urls/getUrls",
      {
        requester: getRequester().replace(/['"]+/g, ""),
        token: getToken().replace(/['"]+/g, ""),
      }
    );

    return { data: response.data };
  } catch (error) {
    console.log(error);
    return { error: error.response };
  }
};

/***************************
 * DELETE LINK
 ***************************/

export const deleteLink = async (id) => {
  try {
    console.log(getToken().replace(/['"]+/g, ""));
    console.log(getRequester().replace(/['"]+/g, ""));
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "urls/delete/" + id,
      {
        requester: getRequester().replace(/['"]+/g, ""),
        token: getToken().replace(/['"]+/g, ""),
      }
    );
    return { data: response.data };
  } catch (error) {  
    console.log(error);
    return { error: error.response };
  }
};

/***************************
 * UPDATE LINK
 ***************************/

export const updtLink = async (id, name, original_url) => {
  try {
    console.log(getToken().replace(/['"]+/g, ""));
    console.log(getRequester().replace(/['"]+/g, ""));
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "urls/update/" + id,
      {
        requester: getRequester().replace(/['"]+/g, ""),
        token: getToken().replace(/['"]+/g, ""),
        name: name,
        original_url:original_url,

      }
    );
    return { data: response.data };
  } catch (error) {
    console.log(error);
    console.log(getToken().replace(/['"]+/g, ""));
    console.log(getRequester().replace(/['"]+/g, ""));
    return { error: error.response };
  }
};

/***************************
 * DASHBOARD
 ***************************/

export const dashboardDetails = async (date_from, date_to) => {
  try {
    console.log(getToken().replace(/['"]+/g, ""));
    console.log(getRequester().replace(/['"]+/g, ""));
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "dashboard",
      {
        requester: getRequester().replace(/['"]+/g, ""),
        token: getToken().replace(/['"]+/g, ""),
        date_from: date_from, 
        date_to: date_to,
      }
    );

    return { data: response.data };
  } catch (error) {
    console.log(error);
    return { error: error.response };
  }
};

/***************************
 * VIEW LINK
 ***************************/

export const linkDetails = async (id, date_from, date_to) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "analytics/" + id,
      {
        requester: getRequester().replace(/['"]+/g, ""),
        token: getToken().replace(/['"]+/g, ""),
        date_from: date_from, 
        date_to: date_to,
      }
    );

    return { data: response.data };
  } catch (error) {
    console.log(error);
    return { error: error.response };
  }
};