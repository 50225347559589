import { useNavigate } from 'react-router-dom';
import { signUpUser } from '../../Helpers/API/authApi';
import './Signup.css';
import React, {useState} from 'react';
import { Form, Row, Col } from "react-bootstrap";

import Logo from '../../Assets/Login/logo.svg'

function Signup() {
  const navigate = useNavigate()
  const[formData, setFormData] = useState({
    lastname: '',
    middleInitial: '',
    firstName: '',
    birthDate: '',
    pronouns: '',
    organization: '',
    address: '',
  })
  async function Signup(){
    navigate("/")
    const response = await signUpUser(formData.lastname, formData.middleInitial, formData.firstName, 
      formData.birthDate, formData.pronouns, formData.organization, formData.address, formData.branch_code)
      console.log(response)
      if(response.data){
        navigate("/dashboard")
      }
      if(response.error){
        alert("Error");
      }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    Signup()
    
  };

  const handleChange = (event) => {
    setFormData({...formData, [event.target.id]: event.target.value })
  };

  return (
    <div class="main">
      <div class="container-fluid">
        <div class="form-wrapper">
          <form>
            <div className="d-flex justify-content-end mb-3">
              <img src={Logo} width={150} height={60} alt=""/>
            </div>

            <h3 class="fw-bold mb-3">SIGN UP</h3>

            <div class="row mb-3">
              <div class="form-group col-5 p-1">
                <label for="lastName">Last Name</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="lastName" 
                  placeholder="Cruz"
                  value={formData.lastName}
                  onChange={handleChange}/>
              </div>

              <div class="form-group col-5 p-1">
                <label for="firstName">First Name</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="firstName" 
                  placeholder="Juan" 
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="form-group col-2 p-1">
                <label for="middleInitial">M. I.</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="middleInitial" 
                  placeholder="M."
                  value={formData.middleInitial}
                  onChange={handleChange}/>
              </div>
            </div>

            <div class="row mb-3">
              <div class="form-group col-4 p-1">
                <label for="birthDate">Birthday</label>
                <input 
                  type="date" 
                  class="form-control" 
                  id="birthDate"
                  value={formData.birthDate}
                  onChange={handleChange}
                />
              </div>

              <div class="form-group col-4 p-1">
                <label for="age">Age</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="age"
                  value={formData.age}
                  onChange={handleChange}
                />
              </div>

              <div class="form-group col-4 p-1">
                <label for="pronouns">Pronouns</label>
                <Form.Select
                  name="pronouns"
                  value={formData.pronouns}
                  onChange={handleChange}
                >
                  <option value="">Prefer not to say</option>
                  <option value="He/Him">He/Him</option>
                  <option value="She/Her">She/Her</option>
                  <option value="They/Them">They/Them</option>

{/*                  {options.map((data) => {
                    return <option value={data.value}>{data.label}</option>;
                  })}*/}
                </Form.Select>
{/*                <input 
                  type="text" 
                  class="form-control" 
                  id="gender"
                  value={formData.gender}
                  onChange={handleChange}
                />*/}
              </div>  
            </div>

            <div class="row mb-3">
              <div class="form-group col-4 p-1">
                <label for="organization">Organization</label>
                <input 
                  type="text" 
                  class="form-control"
                  id="organization" 
                  placeholder="Your organization"
                  value={formData.organization}
                  onChange={handleChange}
                />
              </div>

              <div class="form-group col-8 p-1">
                <label for="address">Address</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="address" 
                  placeholder="Street, Barangay, Municipality, Province"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="form-group col-12 p-1">
                <label for="email">E-mail</label>
                <input 
                  type="text" 
                  class="form-control"
                  id="email" 
                  placeholder="email@sample.com"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="form-group col-12 p-1">
                <label for="password">Password</label>
                <input 
                  type="password"
                  class="form-control" 
                  id="password" 
                  placeholder=""
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div class="text-center col-12">
              <button class="signup-btn" type="submit" onClick={handleSubmit}>SIGN UP</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;