import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { dashboardDetails, linkDetails, linkManager } from "../../Helpers/API/urlsApi";
import { useParams } from "react-router-dom";
import QRCode from "qrcode";

//CSS
import "../Links/Links.css";
import "../ViewLink/ViewLink.css";
//Images
import Browser from "../../Assets/Dashboard/browser.png";
import Device from "../../Assets/Dashboard/device.png";
import Clock from "../../Assets/Dashboard/clock.png";

//Component
import Navbar from "../../Components/Navbar/Navbar";
import MYTDateTimeField from "../../Components/Forms/MYTDateTimeField";
import MYTCreateBtn from "../../Components/Buttons/MYTCreateBtn";
import ExportPDF from "../../Components/Exports/ExportPDF";
import ExportCSV from "../../Components/Exports/ExportCSV";
import Table from "../../Components/Table/Table";


//import Chart
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { refreshPage } from "../../Helpers/Utils/Common";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import MYTUpdateBtn from "../../Components/Buttons/MYTUpdateBtn";
import MYTFilterBtn from "../../Components/Buttons/MYTFilterBtn";

function ViewLinks() {
  const [inactive, setInactive] = useState(false);
  const [linkDevice, setLinkDevice] = useState([]);
  const [linkPlatform, setLinkPlatform] = useState([]);
  const [totalLinks, setTotalLinks] = useState([])
  const [masterlist, setMasterList] = useState([])
  const [uniqueClicks, setUniqueClicks] = useState([])
  const [linkTitle, setLinkTitle] = useState([])
  const [shortened_url, setShortenedURL] = useState([])
  const [origURL, setOrigURL] = useState([])
  let {id} = useParams();


  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString();
  };
  
  const getDateTime24HoursAgo = () => {
    const now = new Date();
    const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    return twentyFourHoursAgo.toISOString();
  };

  const [formData, setFormData] = useState({
  date_from: getDateTime24HoursAgo(),
  date_to: getCurrentDateTime()
  });

   

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  
  async function eachLinkDetails(){
    const response = await linkDetails(id, formData.date_from, formData.date_to);
    setLinkTitle(response.data.dashboard_information[4][0].URL_name)
    setTotalLinks(response.data.dashboard_information[2].map(function(element){
      return element.frequency
    }))
    setUniqueClicks(response.data.dashboard_information[4][0].Unique_clicks)
    setShortenedURL("https://shrnqr.ph/" +response.data.dashboard_information[4][0].Shortened_URL)
    setOrigURL(response.data.dashboard_information[3][1].Original_URL)
    if(response.data){
      setLinkPlatform(response.data.dashboard_information[0])
      setLinkDevice(response.data.dashboard_information[1])
      setMasterList(response.data.dashboard_information[3])
    }
    if(response.error){
    }
  }

  const handleFilter = (e) => {
    e.preventDefault();
    eachLinkDetails()
  };

  useEffect(()=>{
    eachLinkDetails();
  },[])

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value })
    console.log(e.target.value)
  };

  const canvasRef = useRef();
  useEffect(() => {
    QRCode.toCanvas(
      canvasRef.current,
      shortened_url,
      (error) => error && console.error(error)
    );
  });

  return (
    <div className="page">
      <Navbar
        onCollapse={(inactive) => {
          setInactive(inactive);
        }}
        active={"LINKS"}
      />
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        {/* First Row */}
        <Row>
          <Col xs="">
            <h1 className="page-title left">VIEW</h1>
          </Col>
          <Col xs="6" className="align-right" style={{ textAlign: "right" }}>
            <ExportPDF
              type={"PDF"}
              name={linkTitle + "_Masterlist"}
              data={masterlist}
              header = {[
                "URL NAME",
                "ORIGINAL URL",
                "IP ADDRESS",
                "DEVICE",
                "PLATFORM",
                "TIME ACCESSED",
              ]}
            />
            <br />
            <ExportCSV
              name={linkTitle + "_Masterlist"}
              data={masterlist}
              headers={[
                {label:"URL NAME", key:"URL_name"},
                {label:"ORIGINAL URL", key:"Original_URL"},
                {label:"DEVICE", key:"device"},
                {label:"IP ADDRESS", key:"ip_address"},
                {label:"PLATFORM", key:"platform_name"},
                {label:"TIME ACCESSED", key:"time_accessed"}
                ]}
            />
          </Col>
        </Row>

        <hr className="hr-line" />

        <Row>
            <Col xs="8 p-2" style={{width: "52%"}}>
              <Card bg="white" style={{border: "3px solid #003049"}}>
                <Card.Body>
                  <Row>
                    <label className="content-text d-block pt-3 center" >{linkTitle}</label>
                    <label className="text-label d-block pt-4 center">{origURL}</label>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col xs="2 p-2">
              <Card bg="warning" border="dark">
                <Card.Body>
                  <Row>
                    <label className="subtitle d-block pt-4 center">{totalLinks.reduce((acc, x ) => acc.concat(+x), []).reduce((a, b) => a + b, 0)}</label> 
                    <label className="text-label d-block pt-3 center">Total Clicks</label>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col xs="2 p-2">
              <Card bg="info" border="dark">
                <Card.Body>
                  <Row>
                    <label className="subtitle d-block pt-4 center">{uniqueClicks}</label> 
                    <label className="text-label d-block pt-3 center">UNIQUE CLICKS</label>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <div className="qrcode">
            <canvas ref={canvasRef} />
            </div>
        </Row>

        <hr className="hr-line" />
        <Row>
          <Col xs="5">
            <MYTDateTimeField
              required
              type={`inline`}
              name="date_from"
              formLabel={`FROM`}
              size={`s`}
              id="date_from"
              onChange={handleChange}
            />
          </Col>
            <Col xs="5">
              <MYTDateTimeField
                required
                type={`inline`}
                formLabel={`TO`}
                size={`s`}
                id="date_to"
                name="date_to"
                onChange={handleChange}
              />
            </Col>
          <Col xs="2">
            <MYTFilterBtn onClick={handleFilter}/>
          </Col>
        </Row>

         {/* RECENT ENGAGEMENTS */}
        {/* <Row>
          <Card text="dark" style={{height: "12rem",border: "3px solid #003049"}} >
            <Card.Body>
              <Card.Title><span><img src={Browser} className="dashboard-icons"></img></span>Recent Engagements</Card.Title>
              <Row className="pt-2">
                {linkDevice.map(device => (
                  <Col className="d-flex justify-content-center">
                    <Row>
                        <Col key={device.device}>
                          <label className="subtitle d-block center pt-4">{device.frequency}</label>
                          <label className="text-label d-block pt-3">{device.device}</label>
                          <label className="text-label d-block center pt-1">IOS</label>
                        </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Row> */}

        <hr className="hr-line" />

        <Row className="pt-3">
          <Card text="dark" style={{height: "11rem",border: "3px solid #003049", boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)"}} >
            <Card.Body>
              <Card.Title><span><img src={Browser} className="dashboard-icons"></img></span>Platform</Card.Title>
              <Row className="pt-2">
                {linkPlatform.map(platform => (
                  <Col className="d-flex justify-content-center">
                    <Row>
                        <Col key={platform.platform}>
                          <label className="subtitle d-block center pt-4">{platform.frequency}</label>
                          <label className="text-label d-block center pt-3">{platform.platform_name}</label>
                        </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Row>

        <Row className="pt-3">
          <Card bg="light" text="dark" style={{height: "11rem",border: "3px solid #003049", boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)"}} >
            <Card.Body>
              <Card.Title><span><img src={Device} className="dashboard-icons"></img></span>Devices</Card.Title>
              <Row className="pt-2">
                {linkDevice.map(device => (  
                  <Col className="d-flex justify-content-center">
                    <Row>
                      <Col key={device.device}>
                        <label className="subtitle d-block center pt-4">{device.frequency}</label>
                        <label className="text-label d-block center pt-3">{device.device}</label>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Row>
        
        {/* <Row className="pt-3">
          <Card bg="light" text="dark" style={{border: "3px solid #003049"}} >
            <Card.Body>
              <Card.Title><span><img src={Clock} className="dashboard-icons p-1"></img></span>Time</Card.Title>
              <Row className="pt-2">
                <Col xs="justify-content-center">
                  <Line className="chart-size" data={data} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row> */}

        <Row className="pt-3">
          <Card bg="light" text="dark" style={{border: "3px solid #003049", boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)"}}>
            <Card.Body>
              <Card.Title><span><img src={Clock} className="dashboard-icons"></img></span> Logs</Card.Title>
              <Row className="pt-2">
                <Col xs="justify-content-center">
                  <Table
                    tableHeaders={[
                      "TIME ACCESSED",
                      "DEVICE",
                      "PLATFORM",
                      "IP ADDRESS",
                      
                    ]}
                    headerSelector={["time_accessed","device","platform","ip_adress",]}
                    tableData={masterlist}
                    showLoader={false}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        
      </div>
    </div>
  );
}

export default ViewLinks;
