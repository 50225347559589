import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "./MenuItem";
import { removeUserSession } from "../../Helpers/Utils/Common";
import { useNavigate } from "react-router-dom";
//css
import "../Navbar/Navbar.css";

//icons
import create from "../../Assets/icons/create.svg";
import dashboard from "../../Assets/icons/Dashboard.svg";
import link from "../../Assets/icons/link.svg";
import settings from "../../Assets/icons/setting.svg";
import logo from "../../Assets/Logo/MYT_nobg.png";
import logout from "../../Assets/icons/logout.svg";

const Navbar = (props) => {
  //BU Head
  const buHeadItems = [
    {
      name: "CREATE NEW",
      exact: true,
      to: "/create",
      iconClassName: create,
    },
    {
      name: "DASHBOARD",
      exact: true,
      to: "/dashboard",
      iconClassName: dashboard,
    },
    {
      name: "LINKS",
      exact: true,
      to: "/links",
      iconClassName: link,
    },
    {
      name: "SETTINGS",
      exact: true,
      to: "/settings",
      iconClassName: settings,
    },
    
  ];

  const [inactive, setInactive] = useState(true);
  const [menuItems, setMenuItems] = useState([]);
  const [expandManage, setExpandManage] = useState(true);

  const handleExpand = (index) => {
    var list = [...menuItems];

    if (!inactive) {
      for (var i = 0; i < list.length; i++) {
        if (i !== index) {
          list[i]["expand"] = true;
        }
      }

      list[index]["expand"] = !list[index]["expand"];

      setMenuItems(list);
    }
  };

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }

    props.onCollapse(inactive);
  }, [inactive]);

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  useEffect(() => {
    setMenuItems(buHeadItems);

    let menuItemsList = document.querySelectorAll(".menu-item");
    menuItemsList.forEach((el, index) => {
      if (menuItems[index].name == props.active) {
        el.classList.add("active");
      }
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItemsList.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  const navigateto = useNavigate();
  const handleLogout = (e) => {
    setTimeout(() => {
      navigateto("/");
      removeUserSession();
    }, 1000);
  };

  return (
    <div className="nav-body">
      <div className={`side-menu ${inactive ? "inactive" : ""}`}> 
      <div className="top-section">
        <div className="logo d-flex justify-content-center">
          <img
            src={logo}
            className="navbar-logo"
            onClick={() => setInactive(!inactive)}
          />
        </div>
        <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
          {inactive ? (
            <div className="max-menu-cont">
              <FontAwesomeIcon
                icon={"angle-double-right"}
                alt={"open"}
                className={"max-menu"}
                aria-hidden="true"
              />
            </div>
          ) : (
            <FontAwesomeIcon
              icon={"angle-double-right"}
              alt={"close"}
              className={"min-menu"}
              aria-hidden="true"
            />
          )}
        </div>
      </div>

      <div className={inactive ? "main-menu" : "main-menu active-menu"}>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            name={menuItem.name}
            exact={menuItem.exact.toString()}
            to={menuItem.to}
            subMenus={menuItem.subMenus || []}
            iconClassName={menuItem.iconClassName}
            expandManage={menuItem.expand}
            setExpandManage={handleExpand}
            index={index}
            activeSub={menuItem.name === props.active}
            onClick={(e) => {
              if (inactive) {
                setInactive(false);
              }
            }}
          />
        ))}
      </div>
      <div className="side-menu-footer">
        <div className="logout-cont" onClick={() => handleLogout()}>
          <img src={logout} className="logout-btn" />
          <span className="logout-label">LOG OUT</span>
        </div>
      </div>

    </div>
    </div>
    
  );
};

export default Navbar;
