import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function Redirection() {
  let {shortened_url} = useParams();

  async function fetchLink(){
    window.location.href = process.env.REACT_APP_LINK + shortened_url;
  }

  useEffect(()=>{
    fetchLink();
  },[])

  return (
    <div className="page">
      Redirecting
    </div>
  );
}
export default Redirection;