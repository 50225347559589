import { postAPICall } from "./axiosMethodCalls";
import { getUser, getKey, getToken, refreshPage, getRequester } from "../Utils/Common";

/***************************
 * LOGIN
 ***************************/

export const loginUser = async (username, password) => {
  console.log(username, password);
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "login", {     
      username: username,
      password: password,
    });

    console.log(response);
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

/***************************
 * SIGN-UP
 ***************************/

export const signUpUser = async (username,password, passwordconf, email, lastName, middleInitial, firstName, birthDate, 
  pronouns, organization, address, contactNum) => {
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "registerUser", {
      username : username,
      password: password,
      passwordconf: passwordconf,
      email: email,
      last_name :lastName,
      middle_name:middleInitial,
      first_name : firstName,
      birthdate : birthDate,
      pronouns : pronouns,
      organization : organization,
      address : address,
      contact_num : contactNum,
    });
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

// /***************************
//  * CREATE-NEW LINK 
//  ***************************/

// export const createNew = async (link, title, branchcode) => {
//   try {
//     const response = await postAPICall(process.env.REACT_APP_LINK + "createNew", {
//       link: link,
//       title: title,
//       branch_code: branchcode,
//     });
//     return { data: response.data };
//   } catch (error) {
//     return { error: error.response };
//   }
// };

/***************************
 * LOGOUT
 ***************************/

export const logoutUser = async () => {
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "logout", {
      requester: getUser(),
      api_key: getKey().replace(/['"]+/g, ""),
      token: getToken().replace(/['"]+/g, ""),
    });

    refreshPage();
    localStorage.removeItem("user");
    return response;
  } catch (error) {
    refreshPage();
    localStorage.removeItem("user");
    return { error: error.response };
  }
};
