import React, { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
//CSS
import "../AddUser/AddUser.css";

//Component
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";
import ExportPDF from "../../Components/Exports/ExportPDF";
import ExportCSV from "../../Components/Exports/ExportCSV";
import MYTDeleteBtn from "../../Components/Buttons/MYTDeleteBtn";
import MYTCancelBtn from "../../Components/Buttons/MYTCancelBtn";

const sampleData = [
  {
    user_id: "324325",
    name: "Wednesday Addams",
    email: "myt@gmail.com",
    contact_num: "0922233333",
    date_registered: "12/13/2022",
  },
  {
    user_id: "112233",
    name: "Charles Quibido",
    email: "quibido@gmail.edu",
    contact_num: "09222222222",
    date_registered: "12/12/2022",
  },
  {
    user_id: "223355",
    name: "Paul John",
    email: "john@gmail.edu",
    contact_num: "091122899",
    date_registered: "12/18/2023",
  },
  {
    user_id: "667788",
    name: "RV Bal",
    email: "rr@gmail.gov",
    contact_num: "0947156771",
    date_registered: "12/19/2023",
  },
];

function TableComponent() {
  const [inactive, setInactive] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function ActionBtn() {
    return (
      <Form.Select size="sm" aria-label="Default select example">
        <option>Select</option>
        <option>Add</option>
        <option>Edit</option>
        <option>Delete</option>
      </Form.Select>
    );
  }

  function ViewBtn() {
    return <Button size="sm">View</Button>;
  }
  function DltBtn() {
    return <MYTDeleteBtn  variant="primary" onClick={handleShow} size="sm" >Delete</MYTDeleteBtn >;
  }

  return (
    <div className="page">
      <Navbar
        onCollapse={(inactive) => {
          setInactive(inactive);
        }}
        active={"TABLES"}
      />
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <Row>
          <Col xs="6" className="mt-3">
            <h1 className="page-title left">USER MANAGER</h1>
          </Col>
          <Col xs="6" className="align-right" style={{ textAlign: "right" }}>
            <ExportPDF
              type={""}
              name={"USER_ListPDF"}
              data={sampleData}
              header={Object.keys(sampleData[0])}
            />
            <br />
            <ExportCSV
              name={"USER_ListCSV"}
              data={sampleData}
              headers={Object.keys(sampleData[0])}
            />
          </Col>
        </Row>
        <hr className="hr-line" />
        <Row>
          <div className="below">
            {/* table */}
            <Table
              tableHeaders={[
                " ",
                ".",
                "USER ID",
                "NAME",
                "EMAIL",
                "CONTACT #",
                "DATE REGISTERED ",
              ]}
              headerSelector={[" "," ","user_id", "name", "email", "contact_num","date_registered"]}
              tableData={sampleData}
              ActionBtn={ActionBtn}
              ViewBtn={ViewBtn}
              DltBtn={DltBtn}
              showLoader={false}
            />
          </div>
        </Row>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>DELETE USER</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <MYTCancelBtn variant="secondary" onClick={handleClose}>
            Close
          </MYTCancelBtn>
          <MYTDeleteBtn variant="primary">Delete</MYTDeleteBtn>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default TableComponent;
