import React, { useState, useEffect    } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

//CSS
import "./LinkManager.css"; //Dashboard.css

//Component
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";
import MYTDeleteBtn from "../../Components/Buttons/MYTDeleteBtn";
import MYTCancelBtn from "../../Components/Buttons/MYTCancelBtn";
import { useNavigate } from "react-router-dom";
import { Dltlink, deleteLink, deletelink, linkManager } from "../../Helpers/API/urlsApi"; // API for Link Manager
import ExportPDF from "../../Components/Exports/ExportPDF";
import ExportCSV from "../../Components/Exports/ExportCSV";
import { refreshPage } from "../../Helpers/Utils/Common";

function Dashboard() {
  const navigate = useNavigate()
  const [inactive, setInactive] = useState(false);
  const [show, setShow] = useState(false);
  const [links, setLinks] = useState([])
  const [deleteId, setDeleteId] = useState("")
  const [viewId, setViewId] = useState("")
  const [editId, setEditId] = useState("")
  const handleClose = () => setShow(false)

  

  function ActionBtn() {
    return (
      <Form.Select size="sm" aria-label="Default select example">
        <option>Select</option>
        <option>Add</option>
        <option>Edit</option>
        <option>Delete</option>
      </Form.Select>
    );
  }
  // UPDATE FUNTION
  async function updateLink(row){
    navigate("/update-link/"+ row.url_id)
  }
  
  const handleSubmit = (row) => {
    setEditId(row.url_id);
    updateLink(row)
  };
  // DELETE FUNCTION
  async function dltLink(){
    const response = await deleteLink(deleteId)
    console.log(response)
    if(response.data){
      refreshPage()
    }
    if(response.error){
      alert("Error")
    }
  }
  
  
  const handleDelete = (e) => {
    e.preventDefault();
    dltLink()
  };

  const handleView = (row) => {
    setViewId(row.id);
    vwLink(row)
  };
  async function vwLink(row){
    navigate("view-link/"+ row.id)
  }
  const handleShow = (row) => {console.log(row); setDeleteId(row.id); setShow(true)}

  
  
  // API for the data inside the table
  async function fetchLinks() {
    const response = await linkManager();
  
    if (response.data) {
      const modifiedLinks = response.data.urls.map((link) => {
        return {
          ...link,
          shortened_url: (
            <a href={"https://shrnqr.ph/" + link.shortened_url} target="_blank" rel="noopener noreferrer">
              {"https://shrnqr.ph/" + link.shortened_url}
            </a>
          ),
        };
      });
      setLinks(modifiedLinks);
    }
    if (response.error) {
      alert("Error");
    }
  }
  
  useEffect(()=>{
    fetchLinks()
  },[])
  

  function EditBtn(row) {
    return <Button onClick={()=>handleSubmit(row)} className="btn-edit" size="sm">Edit</Button>;
  }
  function DltBtn(row){
    return <MYTDeleteBtn variant="primary" onClick={()=>handleShow(row)} size="sm">Delete Link</MYTDeleteBtn >;
  }
  function ViewBtn(row) {
    return <Button onClick={()=>handleView(row)} className="view-btn" size="sm">View</Button>;
  }
  
  return (
    <div className="page">
      <Navbar
        onCollapse={(inactive) => {
          setInactive(inactive);
        }}
        active={"TABLES"}
      />
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <Row>
          <Col xs="6" className="mt-3">
            <h1 className="page-title left">LINK MANAGER</h1>
          </Col>
          <Col xs="6" className="align-right" style={{ textAlign: "right" }}>
            {/* <ExportPDF
              type={""}
              name={"LinksListPDF"}
              data={links}
              header={Object.keys(links[1])}
            />
            <br />
            <ExportCSV
              name={"LinksListCSV"}
              data={links}
              headers={Object.keys(links[0])}
            /> */}
          </Col>
        </Row>
        <hr className="hr-line" />
        <Row>
          <div className="below">
            {/* table */}
            <Table
              tableHeaders={[
                "*",
                " ",
                "ORGINAL URL",
                "SHORTENED URL",
                "TITLE",
                "DATE CREATED",
                "DATE UPDATED",
                ".",
              ]}
              headerSelector={["*","-","original_url","shortened_url","name","added_on","updated_on"," "]}
              tableData={links}
              ActionBtn={ActionBtn}
              EditBtn={(row)=>EditBtn(row)}
              ViewBtn={(row)=>ViewBtn(row)}
              DltBtn={(row)=>DltBtn(row)}
              showLoader={false}
            />
          </div>
        </Row>
      </div>
      
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>DELETE LINK</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <MYTCancelBtn variant="secondary" onClick={handleClose}>
            Close
          </MYTCancelBtn>
          <MYTDeleteBtn onClick={handleDelete} variant="primary">Delete</MYTDeleteBtn>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Dashboard;