import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row } from 'react-bootstrap'

//ICONS
import LogIn from '../../Assets/Login/login.svg';
import Logo from '../../Assets/Login/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

//CSS
import './Login.css';
import { loginUser } from '../../Helpers/API/authApi';
import { useNavigate } from 'react-router-dom';



function Login() {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const[formData, setFormData] = useState({
    username: '',
    password: '',
  })

async function login(){
  //navigate("/dashboard")
  const response = await loginUser(formData.username, formData.password)
  console.log(response.data.data)
  if(response.data){
    localStorage.setItem("address",JSON.stringify(response.data.address));
    localStorage.setItem("birthdate",JSON.stringify(response.data.birthdate));
    localStorage.setItem("contact_num",JSON.stringify(response.data.contact_num));
    localStorage.setItem("email",JSON.stringify(response.data.email));
    localStorage.setItem("first_name",JSON.stringify(response.data.first_name));
    localStorage.setItem("pronouns",JSON.stringify(response.data.pronouns));
    localStorage.setItem("last_name",JSON.stringify(response.data.last_name));
    localStorage.setItem("middle_name",JSON.stringify(response.data.middle_name));
    localStorage.setItem("organization",JSON.stringify(response.data.organization));
    localStorage.setItem("password",JSON.stringify(response.data.password));
    localStorage.setItem("role_id",JSON.stringify(response.data.role_id));
    localStorage.setItem("token",JSON.stringify(response.data.token));
    localStorage.setItem("token_expiry",JSON.stringify(response.data.token_expiry));
    localStorage.setItem("user_id",JSON.stringify(response.data.user_id));
    localStorage.setItem("username",JSON.stringify(response.data.username));

    navigate("/dashboard")
  }
  if(response.error){
    alert("Error")
  }
}


  const handleSubmit = (event) => {
    event.preventDefault();
    login()
  };

  const handleChange = (event) => {
    setFormData({...formData, [event.target.id]: event.target.value })
  };

  return (
    <div className="login-content">
      <div className="LoginLogo" >
        <img src={LogIn} alt="" /> 
      </div>
      <div className='col- 6 login'>
        <div className = "log" style={{overflow:"hidden !important"}}>
            <div class="form-wrapp">
              <form > 
                <h3><img src={Logo} alt="" /></h3>
                <h2>LOGIN</h2>
                <div class="row mb-3">
                  <div class="form-group col" style={{textAlign:'left'}}> 
                      <p>
                        <input 
                          type="text" 
                          class="form-control" 
                          id="username" 
                          placeholder="Email Address or Username"
                          value={formData.username}
                          onChange={handleChange}
                        />
                      </p>
                  </div>
                </div>
            <Row >
              <div className="form-group" style={{textAlign:'left'}}>
                  <div className="input-group">
                    <input
                      type={showPassword ? 'text-password':'password' }
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn show-btn"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                      </button>
                    </div>
                  </div>
              </div>
            </Row>
            <div class="text-right mt-3">
              <h3>
                <div class="links">
                  <a href="/forgot-password">Forgot your password?</a>
                </div>
              </h3>
            </div>

            <div class="text-left mt-3">
              <div className="checkbox-wrapper">
                <label>
                  <input type="checkbox" />
                  <span>Keep me logged in</span>
                </label>
              </div>
            </div>

            <div class="text-center mt-3">
              <button 
                class="w-40 btn btn-info w-40" 
                type="submit" 
                onClick={handleSubmit}
              >
                <h1>LOGIN</h1>
              </button>
            </div>
            <div class="row mb-3">
              <div class="form-group col"> 
                <div class = "links">
                  <h4>
                    <span>Don't have an account? </span>
                    <a href="/signup">Sign Up</a>
                  </h4>
                </div>
              </div>
            </div>
          </form>
        </div>
    </div>
    </div>
    </div>
  );
}
export default Login;