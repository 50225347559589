import "./App.css";
import "./Assets/FontAwesome/index";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route, Switch  } from "react-router-dom";

import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Settings from "./Pages/Settings/Settings";
import CreateNew from "./Pages/CreateNew/CreateNew";
import Links from "./Pages/Links/Links";
import Signup from "./Pages/Signup/Signup";
import UserManager from "./Pages/UserManager/UserManagerComponent";
import UpdateUser from "./Pages/UpdateUser/UpdateUserComponent";
import UpdateLink from "./Pages/UpdateLink/UpdateLinkComponent";
import AddUser from "./Pages/AddUser/AddUserComponent";
import ViewLinks from "./Pages/ViewLink/ViewLinkComponent";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import Redirection from "./Pages/Redirection/Redirection";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/:shortened_url" element={<Redirection />} />
          <Route path="/static" element={<Login />} />
          <Route path="/create" element={<CreateNew />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/links" element={<Links />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/user-manager" element={<UserManager />} />
          <Route path="/update-user" element={<UpdateUser />} />
          <Route path="/update-link/:id" element={<UpdateLink />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/links/view-link/:id" element={<ViewLinks />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
