import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { dashboardDetails, linkManager } from "../../Helpers/API/urlsApi";

//CSS
import "../Links/Links.css";
import "./Dashboard.css";
//Images
import Browser from "../../Assets/Dashboard/browser.png";
import Device from "../../Assets/Dashboard/device.png";
import Clock from "../../Assets/Dashboard/clock.png";
//Component
import Table from "../../Components/Table/Table";
import Navbar from "../../Components/Navbar/Navbar";
import MYTDateTimeField from "../../Components/Forms/MYTDateTimeField";
import MYTFilterBtn from "../../Components/Buttons/MYTFilterBtn";
import ExportPDF from "../../Components/Exports/ExportPDF";
import ExportCSV from "../../Components/Exports/ExportCSV";

function Dashboard() {
  const [inactive, setInactive] = useState(false);
  const [dashboardDevice, setDashboardDevice] = useState([]);
  const [dashboardPlatform, setDashboardPlatform] = useState([]);
  const [dashboardEngagemennt, setDashboardEngagement] = useState([]);
  const [dashboardLinks, setDashboardLinks] = useState([]);
  const [dashboardMaster, setDashboardMaster] = useState([]);

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString();
  };
  
  const getDateTime1WeekAgo = () => {
    const now = new Date();
    const OneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    return OneWeekAgo.toISOString();
  };

  const[formData, setFormData]=useState({
    date_from: getDateTime1WeekAgo(),
    date_to: getCurrentDateTime(),
  })

  async function fetchDashboard(){
    const response = await dashboardDetails(formData.date_from, formData.date_to);
    console.log(response)
    if(response.data){
      setDashboardDevice(response.data.dashboard_information[1])
      setDashboardPlatform(response.data.dashboard_information[0])
      setDashboardMaster(response.data.dashboard_information[3])
      setDashboardEngagement(response.data.dashboard_information[2].map(function(element){
        return element.frequency
      }))

    }
    if(response.error){
      
    }
  }

  const handleFilter = (e) => {
    e.preventDefault();
    fetchDashboard()
  };

  useEffect(()=>{
    fetchDashboard()
  },[])

  async function fetchDashboardLinks(){
    const response = await linkManager();
    console.log(response)
    if(response.data){
      setDashboardLinks(response.data.urls.length)

    }
    if(response.error){
      
    }
  }

  useEffect(()=>{
    fetchDashboardLinks()
  },[])

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value })
    console.log(e.target.value)
  };


  return (
    <div className="page">
      <Navbar
        onCollapse={(inactive) => {
          setInactive(inactive);
        }}
        active={"DASHBOARD"}
      />
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        {/* First Row */}
        <Row>
          <Col xs="6">
            <h1 className="page-title left">DASHBOARD</h1>
          </Col>
          <Col xs="6" className="align-right" style={{ textAlign: "right" }}>
            <ExportPDF
              type={"PDF"}
              name={"DashboardLogs"}
              data={dashboardMaster}
              header = {[
                "URL NAME",
                "ORIGINAL URL",
                "IP ADDRESS",
                "DEVICE",
                "PLATFORM",
                "TIME ACCESSED",
              ]}
            />
            <br />
            <ExportCSV
              name={"DashboardLogs"}
              data={dashboardMaster}
              headers={[
                {label:"URL NAME", key:"URL_name"},
                {label:"ORIGINAL URL", key:"Original_URL"},
                {label:"DEVICE", key:"device"},
                {label:"IP ADDRESS", key:"ip_address"},
                {label:"PLATFORM", key:"platform"},
                {label:"TIME ACCESSED", key:"time_accessed"}
                ]}
            />
          </Col>
        </Row>

        <hr className="hr-line" />

        <Row>
            <Col xs="6 p-2">
              <Card bg="white" style={{border: "3px solid #003049"}}>
                <Card.Body>
                  <Row>
                    <label className="content-text d-block pt-3 center" >Charles Dave Quibido</label>
                    <label className="text-label d-block pt-4 center">USER</label>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col xs="3 p-2">
              <Card bg="warning" style={{border: "3px solid #003049"}}>
                <Card.Body>
                  <Row>
                    <label className="subtitle d-block pt-4 center">{dashboardLinks}</label>
                    <label className="text-label d-block pt-3 center">total links</label>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col xs="3 p-2">
              <Card bg="info" style={{border: "3px solid #003049"}}>
                <Card.Body>
                  <Row>
                    <label className="subtitle d-block pt-4 center">{dashboardEngagemennt.reduce((acc, x ) => acc.concat(+x), []).reduce((a, b) => a + b, 0)}</label>
                    <label className="text-label d-block pt-3 center">total clicks</label>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
        </Row>

        <hr className="hr-line" />

        <Row>
          <Col xs="5">
            <MYTDateTimeField
              required
              type={`inline`}
              name = "date_from"
              formLabel={`FROM`}
              size={`s`}
              id="date_from"
              onChange={handleChange}
            />
          </Col>
          <Col xs="5">
            <MYTDateTimeField
              required
              type={`inline`}
              formLabel={`TO`}
              size={`s`}
              id= "date_to" 
              name = "date_to"
              onChange={handleChange}
            />
          </Col>

          <Col xs="2">
            <MYTFilterBtn onClick={handleFilter}/>
          </Col>
        </Row>

        <Row>
          <Card bg="light" text="dark" style={{height: "11rem", border: "3px solid #003049"}}>
            <Card.Body>
              <Card.Title><span><img src={Browser} className="dashboard-icons"></img></span>Top Platforms</Card.Title>
              <Row className="pt-2">
                {dashboardPlatform.map(platform => (
                  <Col className="d-flex justify-content-center">
                    <Row>
                        <Col key={platform.platform}>
                          <label className="subtitle d-block center pt-4">{platform.frequency}</label>
                          <label className="text-label d-block pt-3">{platform.platform_name}</label>
                        </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Row>

        <Row className="pt-3">
          <Card bg="light" text="dark" style={{height: "11rem", border: "3px solid #003049"}}>
            <Card.Body>
              <Card.Title><span><img src={Device} className="dashboard-icons"></img></span>Top Devices</Card.Title>
              <Row className="pt-2">
                {dashboardDevice.map(device => (  
                  <Col className="d-flex justify-content-center">
                    <Row>
                      <Col key={device.device}>
                        <label className="subtitle d-block pt-4 center">{device.frequency}</label>
                        <label className="text-label d-block pt-3">{device.device}</label>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Row>

        <Row className="pt-3">
          <Card bg="light" text="dark" style={{border: "3px solid #003049"}}>
            <Card.Body>
              <Card.Title><span><img src={Clock} className="dashboard-icons"></img></span> Logs</Card.Title>
              <Row className="pt-2">
                <Col xs="justify-content-center">
                  <Table
                    tableHeaders={[
                      "URL NAME",
                      "ORIGINAL URL",
                      "IP ADDRESS",
                      "DEVICE",
                      "PLATFORM",
                      "TIME ACCESSED",
                    ]}
                    headerSelector={["URL_name","Original_URL","ip_adress","platform","device","time_accessed"]}
                    tableData={dashboardMaster}
                    showLoader={false}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        
      </div>
    </div>
  );
}

export default Dashboard;
